<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <div class="keywords bgcolor-m">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            :background="COLOR_M"
            placeholder="请输入搜索关键词"
            ref="keyword"
            @focus="search"
          ></van-search>
        </van-col>
      </van-row>
    </div>
    <van-dropdown-menu class="dropdown">
      <van-dropdown-item
        ref="classify"
        v-model="classifyCode"
        @change="classifyChose"
        :options="classifyOptions"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <van-list
      :finished="finished"
      finished-text="没有更多了"
      offset="0"
      class="task-list"
      :immediate-check="false"
      @load="retrieveTaskList"
    >
      <template #default>
        <van-row>
          <van-col v-for="task in taskList" :key="task.code" span="12">
            <div class="task" @click="detail(task.code)">
              <div class="lable">
                <van-tag :color="COLOR_S1">热门</van-tag>
              </div>
              <div class="image">
                <van-image :src="task.imagePath"></van-image>
              </div>
              <div class="name">{{ task.name }}</div>
              <van-row>
                <van-col span="12" class="type"
                  ><van-tag :color="COLOR_M" v-if="task.type === 'ONE'"
                    >线上培训</van-tag
                  ></van-col
                >
                <van-col span="12" class="price color-s2"
                  ><van-icon name="cash-back-record" />
                  <span v-if="task.priceMin != 0">{{ task.priceMin }}</span
                  ><span v-if="task.priceMin != 0 && task.priceMax != 0"
                    >~</span
                  >
                  <span v-if="task.priceMax != 0">{{ task.priceMax }}</span
                  >元</van-col
                >
              </van-row>
              <div class="cert">
                <van-tag plain :color="COLOR_S1">{{ task.cert }}</van-tag>
              </div>
            </div>
          </van-col>
        </van-row>
        <van-row v-if="taskList.length <= 0">
          <van-col span="24" class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR_M" vertical
        >加载中...</van-loading
      >
    </van-popup>
    <Tabbar active="home" />
    <Loading :show="loadingShow" />
    <LogVisit
      ref="logVisit"
      module-type="TRA"
      module-label="TRA_SEARCH_INDEX"
    />
    <Share ref="share" />
  </div>
</template>
<script>
import { Search, DropdownMenu, DropdownItem, Image, Tag, NavBar, Popup, List, Icon } from 'vant'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
export default {
  components: {
    Tabbar: Tabbar,
    Loading: Loading,
    Share: Share,
    Login: Login,
    LogVisit: LogVisit,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [NavBar.name]: NavBar,
    [Popup.name]: Popup,
    [List.name]: List,
    [Icon.name]: Icon
  },
  data () {
    return {
      finished: false,
      loadingShow: false,
      keyword: '',
      classifyOptions: [{ text: '课程分类', value: '' }],
      classifyCode: '',
      page: { current: 0, size: 10, last: 0 },
      taskList: []
    }
  },
  mounted () {
    document.title = '证书搜索'
    var query = this.$route.query
    if (query.keyword !== undefined && query.keyword !== null) {
      this.keyword = query.keyword
    }
    if (query.classifyCode !== undefined && query.classifyCode !== null) {
      this.classifyCode = query.classifyCode
    }
    this.$refs.login.validate()
  },
  methods: {
    init () {
      this.$refs.logVisit.createLog()
      this.retrieveClassifyOptions()
      this.retrieveTaskList()
      this.initShare()
    },
    initShare () {
      var title = '获取技能证书，就上微职客'
      var desc = '涵盖所有技能证书，通过率100%,快速下证，助您升职加薪!'
      var link = 'https://moc.utopanet.com/train/search?keyword=' + this.keyword
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.initParam(title, desc, link, logo)
    },
    search () {
      this.$router.push({ path: '/mde/search/home', query: { keyword: this.keyword, type: 'TRA' } })
    },
    classifyChose () {
      this.taskList = []
      this.page.current = 0
      this.finished = false
      this.retrieveTaskList()
    },
    detail (code) {
      window.sessionStorage.setItem(this.SESSION_BACKURL, window.location.href)
      this.$router.push({ path: '/mde/train/detail', query: { taskCode: code } })
    },
    async retrieveClassifyOptions () {
      var pd = { upperCode: '0' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/classify/retrieveClassifyOptionTree', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.classifyOptions = this.classifyOptions.concat(res.data)
      }
    },
    async retrieveTaskList () {
      if (!this.finished) {
        this.loadingShow = true
        this.page.current = this.page.current + 1
        var pd = { keyword: this.keyword, classifyCode: this.classifyCode, current: this.page.current, size: this.page.size }
        const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveTaskList', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.taskList = this.taskList.concat(res.data)
          this.page.last = res.page.last
          if (res.data.length <= 0) {
            this.finished = true
          }
        }
        this.loadingShow = false
      }
    },
    back () {
      var role = window.sessionStorage.getItem(this.SESSION_ROLE)
      if (role === 'SEL') {
        this.$router.push({ path: '/mde/seller/home/train' })
      } else {
        this.$router.push({ path: '/mde/train/home' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.keywords {
  .back-btn {
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    color: #fff;
  }
  .text {
    color: #fff;
  }
}
.dropdown {
  ::v-deep .van-dropdown-menu__bar {
    height: 35px;
  }
}
.task-list {
  padding-bottom: 60px;
  width: 100%;
  position: absolute;
  .task {
    text-align: left;
    margin: 5px;
    padding-bottom: 5px;
    border: 1px solid #eee;
    position: relative;
    .image {
    }
    .lable {
      float: left;
      z-index: 1;
      right: 0;
      position: absolute;
    }
    .name {
      padding-left: 5px;
      font-weight: 600;
      height: 20px;
      line-height: 20px;
      text-align: left;
      font-size: 14px;
    }
    .type {
      padding-left: 5px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #bbb;
    }
    .price {
      font-size: 12px;
      font-weight: 600;
      height: 20px;
      line-height: 20px;
      text-align: right;
      padding-right: 10px;
    }
    .cert {
      padding-left: 5px;
      height: 20px;
      line-height: 20px;
      font-size: 10px;
      color: #bbb;
    }
    ::v-deep .van-tag {
      font-size: 10px;
    }
  }
}
.no-record {
  margin-top: 80px;
}
</style>
